<template>
  <div ref="imageTofile">
    <el-row :gutter="0">
      <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
        <div class="grid-content bg-purple">
          <el-table
              ref="singleTable"
              :data="tableData"
              :height="defaultHeight.height"
              slot="empty"
              highlight-current-row
          >
            <el-table-column
                type="index"
                min-width="10"
                :default-sort="{ prop: 'date', order: 'descending' }"
            >
            </el-table-column>

            <el-table-column
                property="date"
                label="测量时间"
                sortable
                min-width="10"
                :formatter="formatTabTime"
            >

            </el-table-column>
            <el-table-column property="result" label="测量结果" width="90">
            </el-table-column>
            <el-table-column label="操作" min-width="10">
              <template slot-scope="scope">
                <el-button
                    @click="handleClick(scope.$index)"
                    type="text"
                    size="small"
                >查看
                </el-button
                >
                <el-button
                    type="text"
                    size="small"
                    @click="removeTable(scope.$index)"
                >移除
                </el-button
                >
              </template>
            </el-table-column>
          </el-table>

        </div>
      </el-col>
      <!--地图显示-->
      <el-col :xs="11" :sm="11" :md="11" :lg="11" :xl="11">
        <div class="grid-content bg-purple-light">
          <div :style="defaultHeight">
            <el-amap
                :vid="'amapVue'"
                :center="center"
                :zoom="zoom"
                :events="events"
                :plugin="plugin"
                defaultCursor="auto"
            >
              <el-amap-polyline
                  :editable="polyline.editable"
                  strokeColor="#FF2D14"
                  :path="polyline.path"
                  :events="events"
              ></el-amap-polyline>
              <el-amap-marker
                  v-for="marker in markers"
                  :key="marker.icon"
                  :position="marker.position"
                  :label="marker.label"
              />
            </el-amap>
          </div>
        </div>
      </el-col>


      <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">

        <div class="grid-content bg-purple" :style="defaultHeight">
          <!--  时钟组件 在没有数据的时候显示，如果有数据的时候正常-->
          <!--          <div v-show="isClock" style="background-color: #90CAF9">-->
          <!--            <clock-name style=" display: flex; align-items: center;justify-content: center"/>-->
          <!--          </div>-->
          <!--          画图显示-->
          <canvas id="myCanvas"></canvas>
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>测量计算结果：</span>
            </div>
            <div class="text item">
              {{ result.date }}<br>
              {{ result.size }}<br>
              {{ result.straightDistance }}<br>
              {{ result.perimeter }}<br>
              {{ result.curveDistance }}
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :xs="3" :sm="3" :md="3" :lg="3" :xl="3">
        <div class="outer grid-content bg-purple-light" :style="defaultHeight">

          <div class="button">
            <el-upload
                action="api/chikyMap/uploadDecryptFiles"
                :on-success="uploadSuccess"
                :before-upload="uploadBefore"
                accept=".record,.nmea"
                :show-file-list="false"
                multiple
            >
              <el-button plain class="right-button right-buttontopimport">
                导入文件
                <i class="el-icon-folder-opened"></i>
              </el-button>
            </el-upload>
            <el-button plain class="right-button" @click="choiceFiles">
              导入设备
              <i class="el-icon-mobile-phone"></i>
            </el-button>
            <input
                ref="filElem"
                type="file"
                class="upload-file"
                webkitdirectory
                @change="getFile"
                style="display: none"
            />
            <el-button class="right-button right-buttontop" @click="kmlDownload"
            >导出KML文件
            </el-button
            >
            <el-button class="right-button" @click="cadDownload"
            >导出CAD文件
            </el-button
            >
            <el-button class="right-button" @click="excelDownload"
            >导出EXCEL
            </el-button
            >
            <el-button class="right-button" @click="imgDownload"
            >导出图片
            </el-button
            >
            <el-button
                class="right-button right-buttontop"
                @click="kmlDownloads"
            >批量导出KML
            </el-button
            >
            <el-button class="right-button" @click="cadDownloads"
            >批量导出CAD
            </el-button
            >
            <el-button class="right-button" @click="excelDownloads"
            >批量导出Excel
            </el-button
            >
            <div class="right-p">
              <p>
                <!-- 技术支持：<br />
                <a target="_blank" href="tencent://message/?v=3&uin=2858020351">
                  <img
                    border="0"
                    src="http://pub.idqqimg.com/wpa/images/counseling_style_52.png"
                    alt="点击这里给我发消息"
                    title="点击这里给我发消息"
                  /> </a
                ><br /><br /><br /> -->
                友情链接：<br/>
                <el-link
                    href="https://web.gstarcad.com/flash/touploadfile"
                    :underline="false"
                    type="primary"
                    style="font-size: 18px"
                    target="_blank"
                >在线查看CAD<i class="el-icon-view el-icon--right"></i
                ></el-link>
              </p>
            </div>
            <span id="time">{{ timeYear }}</span><br/>
            <span>&nbsp;&nbsp;&nbsp;{{ timeDay }}</span>
          </div>
          <!--          <div class="inner">备案号：-->
          <!--            <div>-->
          <!--              &lt;!&ndash;              <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" class="no-underline">&ndash;&gt;-->
          <!--              &lt;!&ndash;                粤ICP备16004202号</a>&ndash;&gt;-->
          <!--              <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" class="no-underline">-->
          <!--                粤ICP备20046342号</a>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>

//时间格式转换字符串js
import {formatDate} from "@/static/js/time";
import html2canvas from "html2canvas";
import request from '@/utils/request'
import {upload} from "@/api/chikyMap";
import {Message} from "element-ui";
import ClockName from "@/view/components/clock";

export default {
  name: 'chikyMap',
  components: {ClockName},
  data() {
    return {
      // 是否显示时钟组件,初始化的时候显示
      isClock: true,
      //表格数据
      tableData: [],
      //右上角时间
      timeYear: '',
      timeDay: '',
      //Measured对象
      measured: [],
      //测量计算结果
      result: {},
      //地图数据
      zoom: 9,
      //地图居中显示坐标
      center: [116.398, 39.9089],
      polyline: {
        //坐标轨迹序列
        path: [],
        editable: false,
      },
      markers: [],
      //选中当前行的索引，默认为0 选中第一行
      index: 0,
      //选中当前行
      currentRow: null,
      //高度自适应
      defaultHeight: {
        height: "",
      },
      //给地图绑定事件
      events: {
        click: (e) => {
          this.markers.push({
            position: [e.lnglat.lng, e.lnglat.lat],
            label: {
              content: e.lnglat.lng + "," + e.lnglat.lat,
              offset: [-55, -25],
            },
          });
        },
      },
      //地图插件
      plugin: [
        {
          pName: "MapType",
          defaultType: 0,
          showRoad: true,
        },
        {
          pName: "Scale",
        },
        {
          pName: "ToolBar",
        },
      ],
    };
  },
  created() {
    //右上角时间显示
    this.timeStart();
    //页面创建时执行一次getHeight进行赋值，顺道绑定resize事件
    window.addEventListener("resize", this.getHeight);
    this.getHeight();
  },
  methods: {

    // 格式化表格时间
    formatTabTime(time) {
      // 获取yyyy-mm-dd格式的时间
      let now = new Date(time.date),
          y = now.getFullYear(),
          m = now.getMonth() + 1,
          d = now.getDate();
      return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
    },
    //右上角时间动态显示
    timeStart() {
      // 设置定时器
      this.timer = setInterval(() => {
        let timeArry = formatDate(new Date());
        timeArry = timeArry.split(" ");
        this.timeYear = timeArry[0];
        this.timeDay = timeArry[1];
      }, 1000);
    },
    // 文件上传前
    uploadBefore(file) {
      if (file.size === 0) {
        this.$alert("上传的文件为空，请检查后重试。", "上传失败", {
          confirmButtonText: "确定",
        });
        return false;
      }
    },
    // 导入文件回调
    uploadSuccess(response) {
      console.log(response);
      // 导入文件不走axios拦截器
      if (response.code !== 200) {
        Message({
          message: response.msg,
          type: 'error',
          duration: 5 * 1000

        })
        return Promise.reject(new Error(response.msg))
      }
      // 如果是重复文件则过滤
      for (let i = 0; i < this.measured.length; i++) {
        if (this.measured[i].uuid === response.data[0].uuid) {
          return;
        }
      }
      this.measured.push(response.data[0]);
      // 增加表格数据
      this.refreshTable();
      // 查看第一条数据
      this.handleClick(this.measured.length - 1);
    },
    // 增加表格数据
    refreshTable() {
      let measured = this.measured
      let tab = [];
      // 判断是直线测量||面积测量
      for (let i = 0; i < measured.length; i++) {
        if (+measured[i].measureMode >= 16) {
          if (measured[i].straightDistance) {
            tab.push({result: measured[i].straightDistance + " 米", date: measured[i].unixTimestamp});
          } else {
            tab.push({result: measured[i].perimeter + " 米", date: measured[i].unixTimestamp});
          }
        } else {
          if (measured[i].result > 0) {
            tab.push({result: measured[i].muValue + " 亩", date: measured[i].unixTimestamp});
          }
          // 如果measured[i].result <= 0，则一律视为0
          else {
            measured[i].muValue = 0;
            tab.push({result: measured[i].muValue + " 亩", date: measured[i].unixTimestamp});
          }
        }
      }
      this.tableData = tab;
    },
    //上传设备(打开文件选择)
    choiceFiles() {
      this.$refs.filElem.click();
    },
    //导入设备
    getFile(e) {
      // 打开遮罩层
      const loading = this.$loading({
        lock: true,
        text: "导入文件中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // 检查文件是否为空
      let formData = new FormData();
      let files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        if (files[i].size === 0) {
          this.$message.error("其中" + files[i].name + "文件为空，解析失败")
          continue;
        }
        formData.append("file", files[i]);
      }
      // 上传文件
      upload(formData).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          // 判断是否有数据
          if (this.measured.length > 0) {
            // 过滤重复数据
            let isOK = false; // uuid是否相等
            for (let j = 0; j < this.measured.length; j++) {
              if (this.measured[j].uuid === res.data[i].uuid) {
                isOK = true;
              }
            }
            if (!isOK) {
              this.measured.push(res.data[i]);
            }
          } else {
            this.measured.push(res.data[i]);
          }
        }
        // 增加表格数据
        this.refreshTable();
      }).finally(() => {
        // 关闭遮罩层
        loading.close();
      })
    },
    //查看按钮
    handleClick(index) {
      // 隐藏时钟组件
      this.isClock = false;
      this.index = index;
      //显示测量计算结果
      this.measurementResult(index);
      //2023年7月1日补丁 如果地图上没有坐标点，什么都不用显示，并把中心点定位北京
      if (this.measured[index].longitudeAndLatitude.length < 1) {
        this.center = [116.397459, 39.909212];
      } else {
        //在地图上显示轨迹
        this.maptrack(index);
      }
      //canvas控件显示
      this.canvas(index);
    },
    //在地图上显示轨迹
    maptrack(index) {
      //转换坐标系
      request({
        url: "chikyMap/transformation",
        method: "post",
        data: this.measured[index].longitudeAndLatitude,
      }).then((res) => {
        //转换后的坐标系
        let lonAndLat = res;
        //起点经纬度
        let startingPoint = lonAndLat[0];
        //终点经纬度
        let end = lonAndLat[lonAndLat.length - 1];
        this.markers = [
          {
            position: [+startingPoint.longitude, +startingPoint.latitude],
            label: {content: "", offset: [-55, -25]},
          },
          {
            position: [+end.longitude, +end.latitude],
            label: {content: "", offset: [-55, -25]},
          },
        ];
        //计算中心点坐标
        let disparityLongitude =
            Math.abs(+startingPoint.longitude - +end.longitude) / 2; //经度差距
        let disparityLatitude =
            Math.abs(+startingPoint.latitude - +end.latitude) / 2; //纬度差距
        let coreLongitude =
            +startingPoint.longitude > +end.longitude
                ? +end.longitude + disparityLongitude
                : +startingPoint.longitude + disparityLongitude; //中心点经度
        let coreLatitude =
            +startingPoint.latitude > +end.latitude
                ? +end.latitude + disparityLatitude
                : +startingPoint.latitude + disparityLatitude; //中心点纬度
        //设置中心坐标
        this.center = [coreLongitude, coreLatitude];
        //设置轨迹
        let trajectory = [];
        for (let i = 0; i < lonAndLat.length; i++) {
          trajectory.push([lonAndLat[i].longitude, lonAndLat[i].latitude]);
        }
        this.polyline.path = trajectory;
        //设置缩放比
        let straight = this.measured[index].straightDistance; //直线距离 根据直线距离判断缩放比
        let zoom = 0; //缩放比
        if (+straight <= 100) zoom = 19;
        else if (+straight <= 250) zoom = 18;
        else if (+straight <= 500) zoom = 17;
        else if (+straight <= 1000) zoom = 16;
        else if (+straight <= 2000) zoom = 15;
        else if (+straight <= 5000) zoom = 14;
        else if (+straight <= 10000) zoom = 13;
        else if (+straight <= 20000) zoom = 12;
        else if (+straight <= 50000) zoom = 11;
        else if (+straight <= 100000) zoom = 10;
        else if (+straight <= 200000) zoom = 9;
        else if (+straight <= 300000) zoom = 8;
        else if (+straight <= 500000) zoom = 7;
        else if (+straight <= 1000000) zoom = 6;
        else if (+straight <= 2000000) zoom = 5;
        else if (+straight <= 5000000) zoom = 4;
        else if (+straight <= 10000000) zoom = 3;
        else if (+straight > 10000000) zoom = 2;
        this.zoom = zoom;
      });
    },
    //显示测量计算结果
    measurementResult(index) {
      this.result = {}
      let measured = this.measured[index];
      if (measured.unixTimestamp !== null) {
        this.result.date = "时间：" + formatDate(measured.unixTimestamp);
      }

      // 如果是空白文件（没有经纬度点的情况）
      if (measured.longitudeAndLatitude.length < 1) {
        this.result.size = "面积：0 平方米";
        this.result.perimeter = "周长：0 米";
        this.result.straightDistance = "直线：0 米";
        this.result.curveDistance = "曲线：0 米";
        return;
      }
      if (measured.size != 0) {
        // 精确到后两位小数
        measured.size = Number(measured.size).toFixed(2)
        if (measured.muValue !== 0) {
          this.result.size = "面积：" + measured.size + " 平方米(" + measured.muValue + "亩)"
        } else {
          this.result.size = "面积：" + measured.size + " 平方米"
        }
      }
      if (measured.perimeter !== 0) {
        this.result.perimeter = "周长：" + measured.perimeter + " 米"
      }
      if (measured.straightDistance !== 0) {
        this.result.straightDistance = "直线：" + measured.straightDistance + " 米"
      }
      if (measured.curveDistance !== 0) {
        this.result.curveDistance = "曲线：" + measured.curveDistance + " 米"
      }
    },
    //canvas控件显示 html的控件
    canvas(index) {
      let andLatitude = this.measured[index].longitudeAndLatitude;
      //坐标区域长或宽，最大为300px。判断标准：坐标经度差与纬度差，大的那个为300px。再根据经度差与纬度差的比例计算出短的一边有多少px。
      let canvas = document.getElementById("myCanvas");
      //获取当前canvas的宽和高-----主要是为了适应各种电脑分辨率下，图像的显示问题
      let widthas = window.getComputedStyle(canvas).width;
      let heightas = window.getComputedStyle(canvas).height;
      let g_width = widthas.substring(0, widthas.indexOf("p"));
      let g_height = heightas.substring(0, heightas.indexOf("p"));
      // 将当前div的宽高设置给canvas
      canvas.setAttribute("width", g_width);
      canvas.setAttribute("height", g_height);
      let MAXSIZE = g_width * 0.83;
      let HSIZE = g_height * 0.9;
      let maxL = andLatitude[0].longitude;
      let maxB = andLatitude[0].latitude;
      let minL = andLatitude[0].longitude;
      let minB = andLatitude[0].latitude;
      //获取最大最小经纬度差
      let value;
      for (let i = 0; i < andLatitude.length; i++) {
        value = andLatitude[i];
        maxL = maxL < value.longitude ? value.longitude : maxL;
        maxB = maxB < value.latitude ? value.latitude : maxB;
        minL = minL > value.longitude ? value.longitude : minL;
        minB = minB > value.latitude ? value.latitude : minB;
      }

      let diffL = maxL - minL; //经度差
      let diffB = maxB - minB; //纬度差
      let width, height, Rate, diff;
      if (diffL === 0) {
        width = MAXSIZE;
        height = HSIZE;
        Rate = HSIZE / parseFloat(diffB);
      } else if (diffB === 0) {
        width = MAXSIZE;
        height = HSIZE;
        Rate = MAXSIZE / parseFloat(diffL);
      } else if (diffL >= diffB) {
        diff = diffL;
        width = MAXSIZE;
        Rate = MAXSIZE / parseFloat(diffL); //单位坐标的有多少个px值。
        height = (diffB / diffL) * HSIZE;
      } else {
        diff = diffB;
        height = HSIZE;
        Rate = HSIZE / parseFloat(diffB); //单位坐标的有多少个px值。
        width = (diffL / diffB) * MAXSIZE;
      }
      let ctx = document.getElementById("myCanvas").getContext("2d");
      // 根据B,L计算像素位置。计算应该有px。
      for (let k = 0; k < andLatitude.length; k++) {
        value = andLatitude[k];
        if (diffL === 0) {
          andLatitude[k]["Lpx"] = MAXSIZE / 2;
          andLatitude[k]["Bpx"] = parseInt(
              height - (value.latitude - minB) * Rate
          );
        } else if (diffB === 0) {
          andLatitude[k]["Lpx"] = parseInt((value.longitude - minL) * Rate);
          andLatitude[k]["Bpx"] = HSIZE / 2;
        } else {
          andLatitude[k]["Lpx"] = parseInt((value.longitude - minL) * Rate);
          andLatitude[k]["Bpx"] = parseInt(
              height - (value.latitude - minB) * Rate
          );
        }
      }

      maxL = parseInt((maxL - minL) * Rate);
      maxB = parseInt(height - (maxB - minB) * Rate);
      minL = parseInt((minL - minL) * Rate);
      minB = parseInt(height - (minB - minB) * Rate);
      ctx.translate(
          (g_width - Math.abs(maxL - minL)) / 2,
          (g_height - Math.abs(maxB - minB)) / 2 - Math.abs(maxB)
      );

      ctx.font = "bold 14px Arial";
      ctx.textAlign = "left";
      ctx.textBaseline = "middle";

      ctx.lineWidth = 2;
      for (let i = 0; i < andLatitude.length - 1; i++) {
        ctx.strokeStyle = "rgb(29,143,254)";
        ctx.beginPath();
        ctx.moveTo(andLatitude[i]["Lpx"], andLatitude[i]["Bpx"]);
        ctx.lineTo(andLatitude[i + 1]["Lpx"], andLatitude[i + 1]["Bpx"]);
        ctx.stroke();
      }

      //起点和终点连线
      ctx.setLineDash([1, 3]);
      ctx.lineWidth = 1;
      ctx.strokeStyle = "red";
      ctx.beginPath();
      ctx.moveTo(andLatitude[0]["Lpx"], andLatitude[0]["Bpx"]);
      ctx.lineTo(
          andLatitude[andLatitude.length - 1]["Lpx"],
          andLatitude[andLatitude.length - 1]["Bpx"]
      );
      ctx.stroke();

      //起点和终点设置红色的点
      ctx.fillStyle = "rgb(255,255,0)";
      ctx.beginPath();
      ctx.arc(
          andLatitude[0]["Lpx"],
          andLatitude[0]["Bpx"],
          3,
          0,
          Math.PI * 2,
          false
      );
      ctx.fill();

      ctx.fillStyle = "rgb(0,255,0)";
      ctx.beginPath();
      ctx.arc(
          andLatitude[andLatitude.length - 1]["Lpx"],
          andLatitude[andLatitude.length - 1]["Bpx"],
          3,
          0,
          Math.PI * 2,
          false
      );
      ctx.fill();
    },
    //移除数据
    removeTable(index) {
      this.measured.splice(index, 1);
      this.tableData.splice(index, 1);
      if (this.measured.length > 0) {
        this.handleClick(0);
      }
    },
    //下载kml文件
    kmlDownload() {
      if (!this.judgeisNull()) {
        return;
      }
      this.$confirm("此操作将导出该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            const loading = this.$loading({
              lock: true,
              text: "导出KML中",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            request({
              url: "chikyMap/kmlDownload",
              method: "post",
              data: this.measured[this.index],
              responseType: "blob",
            }).then((res) => {
              if (!res) {
                return;
              }
              const link = document.createElement("a");
              let blob = new Blob([res], {
                type: "application/vnd.ms-excel",
              });
              link.style.display = "none";
              link.href = URL.createObjectURL(blob);
              link.setAttribute(
                  "download",
                  this.measured[this.index].fileName + ".kml"
              );
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              loading.close();
              this.$message({
                type: "success",
                message: "导出成功!",
              });
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消导出",
            });
          });
    },
    //下载CAD
    cadDownload() {
      if (!this.judgeisNull()) {
        return;
      }
      this.$confirm("此操作将导出该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            const loading = this.$loading({
              lock: true,
              text: "导出CAD中",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            request({
              url: "/chikyMap/cadDownload",
              method: "post",
              data: this.measured[this.index],
              responseType: "blob",
            }).then((res) => {
              if (!res) {
                return;
              }
              const link = document.createElement("a");
              let blob = new Blob([res], {
                type: "application/vnd.ms-excel",
              });
              link.style.display = "none";
              link.href = URL.createObjectURL(blob);
              link.setAttribute(
                  "download",
                  this.measured[this.index].fileName + ".dxf"
              );
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              loading.close();
              this.$message({
                type: "success",
                message: "导出成功!",
              });
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消导出",
            });
          });
    },
    //下载Excel
    excelDownload() {
      if (!this.judgeisNull()) {
        return;
      }
      this.$confirm("此操作将导出该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            const loading = this.$loading({
              lock: true,
              text: "导出EXCEL中",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            request({
              url: "chikyMap/excelDownload",
              method: "post",
              data: this.measured[this.index],
              responseType: "blob",
            }).then((res) => {
              if (!res) {
                return;
              }
              const link = document.createElement("a");
              let blob = new Blob([res], {
                type: "application/vnd.ms-excel",
              });
              link.style.display = "none";
              link.href = URL.createObjectURL(blob);
              link.setAttribute(
                  "download",
                  this.measured[this.index].fileName + ".xls"
              );
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              loading.close();
              this.$message({
                type: "success",
                message: "导出成功!",
              });
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消导出",
            });
          });
    },
    //导出图片
    imgDownload() {
      if (!this.judgeisNull()) {
        return;
      }
      this.$confirm("此操作将导出该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            const loading = this.$loading({
              lock: true,
              text: "导出图片中",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            // 手动创建一个 canvas 标签
            const canvas = document.createElement("canvas");
            // 获取父标签，意思是这个标签内的 DOM 元素生成图片
            // imageTofile是给截图范围内的父级元素自定义的ref名称
            let canvasBox = this.$refs.imageTofile;
            // 获取父级的宽高
            const width = parseInt(window.getComputedStyle(canvasBox).width);
            const height = parseInt(window.getComputedStyle(canvasBox).height);
            // 宽高 * 2 并放大 2 倍 是为了防止图片模糊
            canvas.width = width * 2;
            canvas.height = height * 2;
            canvas.style.width = width + "px";
            canvas.style.height = height + "px";
            const context = canvas.getContext("2d");
            context.scale(2, 2);
            const options = {
              backgroundColor: null,
              canvas: canvas,
              useCORS: true,
            };
            html2canvas(canvasBox, options).then((canvas) => {
              if (window.navigator.msSaveOrOpenBlob) {
                //ie浏览器
                let dataURL = canvas.toDataURL("image/png");
                let arr = dataURL.split(','), mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while (n--) {
                  u8arr[n] = bstr.charCodeAt(n);
                }
                let blob = new Blob([u8arr], {type: mime})
                window.navigator.msSaveOrOpenBlob(blob, "as.png");

                loading.close();
                this.$message({
                  type: "success",
                  message: "导出成功!",
                });
              } else {
                //普通浏览器
                let dataURL = canvas.toDataURL("image/png");
                //直接创建一个 a 标签直接下载
                let a = document.createElement("a");
                a.href = dataURL;
                a.download = this.measured[this.index].fileName + ".png";
                a.click();
                loading.close();
                this.$message({
                  type: "success",
                  message: "导出成功!",
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消导出",
            });
          });
    },
    //批量导出KML
    kmlDownloads() {
      if (!this.judgeisNull()) {
        return;
      }
      this.$confirm("此操作将导出该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            const loading = this.$loading({
              lock: true,
              text: "批量导出KML中",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            request({
              url: "chikyMap/kmlDownloads",
              method: "post",
              data: this.measured,
              responseType: "blob",
            }).then((res) => {
              if (!res) {
                return;
              }
              const link = document.createElement("a");
              let blob = new Blob([res], {
                type: "application/vnd.ms-excel",
              });
              link.style.display = "none";
              link.href = URL.createObjectURL(blob);
              link.setAttribute("download", "批量kml" + ".zip");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              loading.close();
              this.$message({
                type: "success",
                message: "导出成功!",
              });
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消导出",
            });
          });
    },
    //批量导出CAD
    cadDownloads() {
      if (!this.judgeisNull()) {
        return;
      }
      this.$confirm("此操作将导出该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            const loading = this.$loading({
              lock: true,
              text: "正在批量导出CAD",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            request({
              url: "chikyMap/cadDownloads",
              method: "post",
              data: this.measured,
              responseType: "blob",
            }).then((res) => {
              if (!res) {
                return;
              }
              const link = document.createElement("a");
              let blob = new Blob([res], {
                type: "application/vnd.ms-excel",
              });
              link.style.display = "none";
              link.href = URL.createObjectURL(blob);
              link.setAttribute("download", "批量CAD" + ".zip");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              loading.close();
              this.$message({
                type: "success",
                message: "导出成功!",
              });
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消导出",
            });
          });
    },
    //批量导出excel
    excelDownloads() {
      if (!this.judgeisNull()) {
        return;
      }
      this.$confirm("此操作将导出该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            const loading = this.$loading({
              lock: true,
              text: "正在批量导出EXCEL",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            request({
              url: "chikyMap/excelDownloads",
              method: "post",
              data: this.measured,
              responseType: "blob",
            }).then((res) => {
              if (!res) {
                return;
              }
              const link = document.createElement("a");
              let blob = new Blob([res], {
                type: "application/vnd.ms-excel",
              });
              link.style.display = "none";
              link.href = URL.createObjectURL(blob);
              link.setAttribute("download", "批量EXCEL" + ".zip");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              loading.close();
              this.$message({
                type: "success",
                message: "导出成功!",
              });
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消导出",
            });
          });
    },
    //判断是否导入文件
    judgeisNull() {
      if (this.measured.length > 0) {
        return true;
      } else {
        this.$message({
          type: "error",
          message: "请先导入文件或设备",
        });
        return false;
      }
    },
    //获取浏览器高度
    getHeight() {
      this.defaultHeight.height = window.innerHeight + "px";
    },
  },
};
</script>

<style scoped>
a.no-underline {
  text-decoration: none; /* 移除下划线 */
}

.inner {
  /*background-color: #f1f1f1; !* 可选：设置背景颜色 *!*/
  padding: 5px 0px; /* 可选：设置内边距 */
  margin: 60px 0px 5px 30px;
  position: absolute; /* 将元素设置为绝对定位 */
  bottom: 1%; /* 将元素置于页面底部 */
}

/*/!************/
html,
body {
  height: 100%;
  overflow: auto;
  margin: 0;
}

html {
  overflow-y: auto;
}

#myCanvas {
  border: 1px solid #ccc;
  overflow: hidden;
  width: 100%;
  height: 50%;
}

.text {
  font-size: 20px;
  line-height: 50px;
}

.box-card {
  height: 50%;
}

.button {
  margin-left: 40px;
}

.right-button {
  margin-left: 10px;
  margin-top: 10px;
  width: 120px;
}

#time {
  font-size: 18px;
}

.right-buttontop {
  margin-top: 50px;
}

.right-buttontopimport {
  margin-top: 20px;
}

.right-p {
  margin-top: 50px;
}
</style>
